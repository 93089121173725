



























































import { SocialpostTopic } from "@/models/socialpost-topic.model";
import Vue from "vue";
import Component from "vue-class-component";
import Vuetable from "vuetable-2";
import { namespace } from "vuex-class";

const socialpostTopicsModule = namespace("SocialpostTopics");

@Component({ components: { Vuetable } })
export default class SocialpostTopics extends Vue {
  isLoading = false;
  fields = [
    { name: "title", sortField: "title" },
    { name: "description", sortField: "description" },
    { name: "actions", sortField: "Actions" },
  ];

  @socialpostTopicsModule.Getter("all")
  socialpostTopics!: SocialpostTopic[];

  @socialpostTopicsModule.Action("fetchAll")
  fetchAll!: () => Promise<SocialpostTopic[]>;

  @socialpostTopicsModule.Action("delete")
  delete!: (socialpostTopic: string) => Promise<SocialpostTopic>;

  @socialpostTopicsModule.Action("create")
  create!: (socialpostTopic: SocialpostTopic) => Promise<SocialpostTopic>;

  get locale() {
    return this.$root.$i18n.locale;
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  doCreate() {
    this.$router.push({ name: "socialpost-topics-create" });
  }

  async doCopy(socialpostTopic: SocialpostTopic) {
    const { id, ...rest } = socialpostTopic;
    const newSocialTopic = await this.create(rest as any);
    this.doEdit(newSocialTopic);
  }

  doEdit(socialpostTopic: SocialpostTopic) {
    this.$router.push({
      name: "socialpost-topics-detail",
      params: { id: socialpostTopic.id },
    });
  }

  async doDelete(socialpostTopic: SocialpostTopic) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${JSON.stringify(
        socialpostTopic.title
      )}?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.delete(socialpostTopic.id);
    }
    this.isLoading = false;
    this.doFetchAll();
  }

  created() {
    this.doFetchAll();
  }
}
